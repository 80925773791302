import React from 'react'
import { BottomNavbar } from '@app/layout';
import { Banner, CardLayout, Cta1, Cta2, Newsletter } from '@pages/home/components';

export function Homepage() {
    return (
        <>
            <BottomNavbar />
            <Banner />
            <Cta1 />
            <Cta2 />
            <CardLayout />
            <Newsletter />
        </>
    )
}

import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { ROLE } from "@common";
import { Layout } from '@app/layout'

export function PrivateRoute({ allowed_roles }: { allowed_roles: ROLE[] }) {
  const { isAuthenticated, role } = useAppSelector((state) => state.auth);

  return isAuthenticated && role && allowed_roles.includes(role) ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace={true} />
  );
}

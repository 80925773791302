import React from "react";
import { Link } from "react-router-dom";

export function Cta2() {
    return (
        <div className="cta2 row mt-3 bg-dark text-white">
            <div className="col-md-1">

            </div>
            <div className="col-md-3">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">WHY YOU SHOULD SELL
                        </h5>

                        <h6 className="card-subtitle">We Do The Work To Sell Your Cards</h6>
                        <p className="card-text">Research & identify your collectibles evaluate condition & value scan the
                            front & back of every card Advertise to find buyers store & insure consigned collectibles
                            resolve customer service needs securely package & ship to buyers</p>
                        <br />

                        <h6 className="card-subtitle">36 Million Cards Under One Roof</h6>
                        <p className="card-text">We house millions of sports cards from thousands
                            of sellers and provide industry renowned
                            customer service!</p>

                    </div>
                </div>
            </div>

            <div className="col-md-7">
                <div className="row">
                    <div className="col">
                        <h1 className="text-left">A Different Way to Sell Cards</h1>
                        <p className="">We identify, scan, list, store, insure, package, and ship - just send
                            us your sports cards, trading cards, and collectibles. COMC listings
                            are automatically posted to eBay!</p>
                        <Link to="" className="btn btn-primary">Sell Cards</Link>
                    </div>
                </div>
            </div>
            <div className="col-md-1">

            </div>
        </div>
    )
}
import { BrowserRouter } from 'react-router-dom';
import { Router } from '@app/routes'
import { Provider } from 'react-redux';
import { store } from '@store/index';
import { ToastContainer } from 'react-toastify';
import { APP_CONSTANTS } from '@common';
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./assets/scss/theme.scss";
import "./assets/scss/style.css";
import { ErrorBoundary } from '@components';

function App() {
  document.title = APP_CONSTANTS.APP_NAME;

  return (
    <ErrorBoundary>
      <div className="App">
        <Provider store={store}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          <ToastContainer />
        </Provider>
      </div>
    </ErrorBoundary>
  );
}

export default App;

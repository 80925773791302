import { createProduct, CreateProductPayload, uploadMedia, getCategories as getCategoriesApi, getSubcategories } from '@api';
import { BOX_TYPE, Category, PRODUCT_STATUS, SubCategory, TRANSACTION, UNITE } from '@common';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AddCardForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let category: any, BoxType: any, subCategory: any;
    if (location.state) {
        category = location.state?.category;
        BoxType = location.state?.BoxType;
        subCategory = location.state?.subCategory;
    }

    const [status, setStatus] = useState('active');
    const [transaction, setTransaction] = useState<TRANSACTION>(TRANSACTION.WANTED);

    const [subCategories, setSubCategory] = useState<SubCategory[]>([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(() => {
        return subCategories.find(category => category._id === subCategory) || { _id: '', name: '' };
    });


    const [boxType, setBoxType] = useState<BOX_TYPE | string>(BoxType);
    const [year, setYear] = useState('');
    const [unit, setUnit] = useState(UNITE.UNIT);
    const [price, setPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage] = useState('');

    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedcategory, setSelectedCategory] = useState(() => {
        return categories.find(category => category._id === subCategory._id) || { _id: '', name: '' };
    });

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = e.target;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const { success, data } = await uploadMedia(file);

            if (success)
                setImage(data);
        } else {

        }
    };

    useEffect(() => {
        getCategories();
        getSubCategoy()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])

    const getCategories = async () => {
        const { success, data } = await getCategoriesApi('All');

        if (!success) return;
        setCategories(data)
    }

    const getSubCategoy = async () => {
        const { success, data } = await getSubcategories('All');
        if (success && data) {
            setSubCategory(data);
        }
    }


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Check if selectedcategory and its _id are defined
        if (!selectedcategory?._id) {

            return;
        }
        if (!selectedSubCategory?._id) {
            return;
        }
        if (typeof description !== 'string') {

            return;
        }

        const formData: CreateProductPayload = {
            status: status as PRODUCT_STATUS,
            transaction: transaction,
            category: selectedcategory._id,
            // Now this is ensured to be a string
            sub_category: selectedSubCategory?._id,
            box_type: boxType as BOX_TYPE,
            year: year,
            unite: unit as UNITE,
            price: parseFloat(price),
            quantity: parseInt(quantity),
            description: description as string,
            image_url: image,
        };

        const { success } = await createProduct(formData);
        if (!success) return;

        const selectedCategory = categories.find(c => c._id === formData.category);
        if (selectedCategory) {
            navigate('/cardListing', { state: { category: selectedCategory } });
        }
    };

    useEffect(() => {

        if (subCategory) {

            const initialSubCategory = subCategories.find(category => category._id === subCategory);

            if (initialSubCategory) {

                setSelectedSubCategory(initialSubCategory);
            }
        }
    }, [subCategories, subCategory]);

    useEffect(() => {

        if (category) {

            const initialCategory = categories.find(cat => cat._id === category);

            if (initialCategory) {

                setSelectedCategory(initialCategory);
            }
        }
    }, [categories, category, subCategories]);

    const handleChange = (e: any) => {
        const selectedSubCategoryId = e.target.value;
        if (selectedSubCategoryId === '') {
            setSelectedSubCategory({ _id: '', name: '' });
        } else {
            const selectedSubCategory = subCategories.find(category => category._id === selectedSubCategoryId);
            if (selectedSubCategory) {
                setSelectedSubCategory(selectedSubCategory);
            }
        }
    };

    const handleCategoryChange = (e: any) => {
        const selectedCategoryId = e.target.value;
        if (selectedCategoryId === '') {
            setSelectedCategory({ _id: '', name: '' });
        } else {
            const selectedSubCategory = categories.find(category => category._id === selectedCategoryId);
            if (selectedSubCategory) {
                setSelectedCategory(selectedSubCategory);
            }
        }
    }

    return (
        <div className="container cart-container">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className='text-center mb-5' style={{ fontFamily: 'Open Sans', fontWeight: 700 }}>Listing</h3>
                    </div>

                    <div className="col-sm-6 d-flex justify-content-around">
                        <label className="mb-2">Status:</label>
                        <div className='d-flex gap-2'>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="statusOptions"
                                    id="inlineRadio1"
                                    value="active"
                                    checked={status === 'active'}
                                    onChange={() => setStatus('active')}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="statusOptions"
                                    id="inlineRadio2"
                                    value="inactive"
                                    checked={status === 'inactive'}
                                    onChange={() => setStatus('inactive')}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 d-flex justify-content-around">
                        <label className="mb-2">Transaction:</label>
                        <div className='d-flex gap-2'>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="transOptions"
                                    id="inlineRadio3"
                                    value={TRANSACTION.WANTED}
                                    checked={transaction === TRANSACTION.WANTED}
                                    onChange={() => setTransaction(TRANSACTION.WANTED)}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">Wanted</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="transOptions"
                                    id="inlineRadio4"
                                    value={TRANSACTION.FORSALE}
                                    checked={transaction === TRANSACTION.FORSALE}
                                    onChange={() => setTransaction(TRANSACTION.FORSALE)}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio4">For Sale</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-6">
                        <label className="mb-2">Category:</label>

                        <select aria-label="Choose an option:"
                            className="form-select"
                            value={selectedcategory?._id ?? ''}
                            onChange={handleCategoryChange}
                            required
                        >
                            <option value="">Select Category</option>
                            {categories.map((cat) =>
                                <option key={cat._id} value={cat._id}>{cat.name}</option>
                            )}
                        </select>
                    </div>
                    <div className="col-sm-6">
                        <label className="mb-2">Sub-Category:</label>
                        <select
                            aria-label="Choose an option:"
                            className="form-select"
                            value={selectedSubCategory._id}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Sub-Category</option>
                            {subCategories.map(category =>
                                <option key={category._id} value={category._id}>{category.name}</option>
                            )}
                        </select>
                    </div>
                </div>


                <div className="row mt-3">
                    <div className="col-sm-6">
                        <label className="mb-2">Box Type</label>
                        <select aria-label="Choose an option:" className="form-select" value={boxType}
                            onChange={(e) => setBoxType(e?.target?.value as BOX_TYPE)}
                            required>
                            <option value="">BOX Type</option>
                            {

                                Object.values(BOX_TYPE).map((boxType, index) => (

                                    <option key={index} value={boxType}>

                                        {boxType}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-sm-6">
                        <label className="mb-2">Year</label>
                        <input type="number" className="form-control" value={year}
                            onChange={(e) => setYear(e.target.value)}
                            placeholder="Enter Year"
                            required />

                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-6">
                        <label className="mb-2">Unit</label>
                        <select aria-label="Choose an option:" className="form-select" value={unit}
                            onChange={(e) => setUnit(e.target.value as UNITE)}>
                            {Object.values(UNITE).map(value => <option value={value}>{value}</option>)}
                        </select>
                    </div>
                    <div className="col-sm-6">
                        <label className="mb-2">Price</label>
                        <input type="text" className="form-control" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Enter Product Price" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-6">
                        <label className="mb-2">Quantity</label>
                        <input type="text" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Enter Product Quantity" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <label className="mb-2">Item Description</label>
                        <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} rows={6} placeholder="Enter text"></textarea>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Images Uploaded size of below 40 Mb</label>
                            <input className="form-control" type="file" id="formFile" onChange={(e) => handleImageUpload(e)} />
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <button type="submit" className="btn btn-primary me-2">Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => { navigate(-1) }}>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export { AddCardForm };

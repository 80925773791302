export enum STATUS {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    BLOCKED = 'Blocked',
    PENDING = 'Pending',
}

export enum ROLE {
    ADMIN = 'admin',
    USER = 'user',
}

export enum CATEGORY_TYPE {
    SPORTS = 'Sports',
    GAMING = 'Gaming',
    SUPPLIES = 'Supplies',
    OTHER = 'Other',
}
export enum Sub_Category_Type {

}

export enum BOX_TYPE {

    HOBBY = "Hobby",
    BLASTER = "Blaster",
    BOOSTER = "Booster",
    BREAKAWAY = "Breakaway",
    BREAKERS_DELIGHT = "Breakers Delight",
    CELLS = "Cellos",
    CELLS_NO_SHRINK_WRAP = "Cellos (no shrink wrap)",
    CHOICE = "Choice",
    FOTL = "FOTL",
    FANATICS = "Fanatics",
    FAST_BREAK = "Fast Break",
    GRAVITY_FEED = "Gravity Feed",
    HANGER = "Hanger",
    HYBRID = "Hybrid",
    JUMBOS_HTA = "Jumbos/HTA",
    LITE = "LITE",
    LUCKY_ENVELOPE = "Lucky Envelope",
    MEGA = "Mega",
    MONSTER = "Monster",
    NO_HUDDLE = "No Huddle",
    QUICK_PITCH = "Quick Pitch",
    RETAIL_OTHER = "Retail/Other",
    SET_HAND_COLLATED = "Set (hand collated)",
    SUPER = 'Super',
    SUPER_JUMBOS = 'Super Jumbos',
    T_MALL = 'T-mall',
    TINS = 'Tins',
    UNDER_CARD = 'Under Card',
    VENDING = 'Vending',
    X = 'x',
}

export enum UNITE {
    UNIT = 'Unit',
    SETS = 'Sets'
}

export enum TRANSACTION {
    WANTED = 1,
    FORSALE = 2
}

export enum PRODUCT_STATUS {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}
import { ROLE } from "@common";

export const isAdmin = (role: ROLE | undefined | null): boolean => {
    if (!role) return false;
    return [ROLE.ADMIN].includes(role);
};

export const isUser = (role: ROLE | undefined | null): boolean => {
    return role === ROLE.USER;
};

export const isTokenExpired = (token: string): boolean => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    const { exp } = JSON.parse(jsonPayload);
    const expired = Date.now() >= exp * 1000;
    return expired;
};

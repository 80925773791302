import React from 'react'
import { UserDetailsForm } from '@pages/auth/components';

export function Registration() {
    return (
        <>
            <UserDetailsForm />
        </>
    )
}


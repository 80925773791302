import React from 'react'
import { BottomNavbar } from '@app/layout';
import { ChildListingLayout } from './components';



export function ChildListing() {
    return (
        <>
            <BottomNavbar />
            <ChildListingLayout />
        </>
    )
}
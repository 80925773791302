import React from "react"
import { Footer } from "@app/layout/Footer"
import { Navbar } from "@app/layout/Navbar"
export const Layout = (props: any) => {
    return (
        <div className="container-fluid">
            <Navbar />
            {props.children}
            <Footer />
        </div>
    )
}
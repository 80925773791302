import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'

export default function UserCard() {
    return (
        <ListItem button key="RemySharp">
            <ListItemIcon>
                <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
            </ListItemIcon>
            <ListItemText primary="Remy Sharp">Remy Sharp</ListItemText>
            <ListItemText secondary="online" ></ListItemText>
        </ListItem>
    )
}

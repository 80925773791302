import React from "react";
import { CardImage } from "@assets/images";
import { Link } from 'react-router-dom';


export function CardLayout() {

    return (
        <div>
            <section className="background-overlay"></section>
            <section className="position-relative">
                <h3>SHOP BY SPORT</h3>
                <div className="row justify-content-center card-row">
                    <div className="col-md-3">
                        <div className="custom-card">
                            <img src={CardImage}
                                className="card-img-top" alt="..." />
                            <div className="card-body">
                                <Link to="" className="btn btn-primary btn-full-width">Sports</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="custom-card">
                            <img src={CardImage}
                                className="card-img-top" alt="..." />
                            <div className="card-body">
                                <Link to="" className="btn btn-primary btn-full-width">Sports</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="custom-card">
                            <img src={CardImage}
                                className="card-img-top" alt="..." />
                            <div className="card-body">
                                <Link to="" className="btn btn-primary btn-full-width">Sports</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="custom-card">
                            <img src={CardImage}
                                className="card-img-top" alt="..." />
                            <div className="card-body">
                                <Link to="" className="btn btn-primary btn-full-width">Sports</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </section>

        </div>
    )
}



import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BOX_TYPE, Category, SubCategory, YEARS } from '@common';
import { getParentProducts, getSubcategories, ProductFilterQuery, ProductParent } from '@api'

type cardTableLocationProps = {
    category: Category
}

const CardTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { category } = location.state as cardTableLocationProps;
    const [selectedYear, setSelectedYear] = useState<string | "">("2024")
    const [product, setProduct] = useState<ProductParent[]>([])
    const [boxType, setBoxType] = useState<string | "">("")
    const [subCategories, setSubCategories] = useState<SubCategory[]>([])
    const [selectedSubCategories, setSelectedSubCategories] = useState<string | "">("")

    const getProduct = async (quer: ProductFilterQuery) => {
        const { success, data } = await getParentProducts(quer)
        if (success)
            setProduct(data)
    }

    const getSubCategory = async () => {
        const { success, data } = await getSubcategories('All');
        if (success && data) {
            setSubCategories(data)
        }
    }

    useEffect(() => {
        getProduct({ category: category._id, year: selectedYear });
        getSubCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category?._id])

    const handleProduct = (product: ProductParent) => {
        navigate('/childListing', {
            state: {
                category: product.category,
                subCategory: product.sub_category,
                BoxType: product.box_type,
                year: product.year,
                high_bid: product?.high_bid !== 0 ? product?.high_bid : " - ",
                low_ask: product?.low_ask !== 999999 ? product?.low_ask : " - ",
            }
        })
    }

    const handleBoxFilter = (e: any) => {
        const boxType = e.target?.value;
        setBoxType(e?.target?.value as BOX_TYPE)
        getProduct({ box_type: boxType, year: selectedYear, category: category._id })
    }

    const handleYearFilter = (e: any) => {
        setSelectedYear(e.target.value)
        getProduct({ year: e.target.value, category: category._id })
    }

    return (
        <div>
            <div className="listing-container">
                <div className="row">
                    <div className="col-sm-3">
                        <label htmlFor="item">Category:{category.name}</label>
                    </div>
                    <div className="col-sm-3">
                        <select aria-label="Choose an option:" className="form-select" value={selectedSubCategories}
                            onChange={(e) => {
                                setSelectedSubCategories(e.target.value)
                                const ProductParams: ProductFilterQuery = { sub_category: e.target.value !== "All" ? e.target.value : '', category: category._id, year: selectedYear }
                                if (boxType !== "") {
                                    ProductParams.box_type = boxType as BOX_TYPE;
                                }
                                getProduct(ProductParams)
                            }}
                            required>
                            <option value="" disabled selected>Select Sub-Category</option>
                            {
                                subCategories.map((cat, index) => (

                                    <option key={index} value={cat._id}>

                                        {cat.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <select aria-label="Choose an option:" className="form-select" id="dropdownMenu1" value={selectedYear} onChange={handleYearFilter}>
                            {
                                YEARS.map((item) => (
                                    <option key={item.id} value={item.year} >
                                        {item.year}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-sm-3">
                        {/* Dropdown Menu 2 */}
                        <select aria-label="Choose an option:" className="form-select" value={boxType}
                            onChange={handleBoxFilter}
                            required>
                            <option value="" disabled selected>Select Box-Type</option>
                            {
                                Object.values(BOX_TYPE).map((boxType, index) => (
                                    <option key={index} value={boxType}>
                                        {boxType}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <table className="table text-center custom-table">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Factory Cost</th>
                        <th>High Bid</th>
                        <th>Low Ask</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        product?.length > 0 ? (
                            product?.map((item, index) => {
                                const selectedSubCategory = subCategories.find((cat) => cat._id === item.sub_category);
                                return (
                                    <tr key={index} onClick={() => handleProduct(item)}>
                                        <td>{item?.year + '-' + selectedSubCategory?.name + '-' + item?.box_type}</td>
                                        <td>{'-'}</td>
                                        <td>{item?.high_bid !== 0 ? item?.high_bid : " - "}</td>
                                        <td>{item?.low_ask !== 999999 ? item?.low_ask : " - "}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={4} className="text-center">No Product found in {selectedYear}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
};

export { CardTable };

import React from 'react'
import { AddCardForm } from '@pages/card/components';
import { BottomNavbar } from '@app/layout';

export function AddCard() {
    return (
        <>
            <BottomNavbar />
            <AddCardForm />
        </>
    )
}


import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="text-left text-white mt-3">
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <ul className="list-unstyled">
              <li><Link to="" className="text-left text-white">Faqs</Link></li>
              <li><Link to="" className="text-left text-white">Membership</Link></li>
              <li><Link to="" className="text-left text-white">About Us</Link></li>
              <li><Link to="" className="text-left text-white">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col-md-2">
            <ul className="list-unstyled">
              <li><Link to="" className="text-left text-white">Sports</Link></li>
              <li><Link to="" className="text-left text-white">Gaming</Link></li>
              <li><Link to="" className="text-left text-white">Supplies</Link></li>
              <li><Link to="" className="text-left text-white">Other</Link></li>
            </ul>
          </div>
          <div className="col-md-2">
            <ul className="list-unstyled">
              <li><Link to="" className="text-left text-white">abc@gmail.com</Link></li>
              <li><Link to="" className="text-left text-white">+674563827</Link></li>
              <li><Link to="" className="text-left text-white">Address</Link></li>
            </ul>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </footer>
  )
}


import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { Layout } from '@app/layout'

export function PublicRoute({ redirect = true }: { redirect?: boolean }) {
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    return isAuthenticated && redirect ? (
        <Navigate to="/" replace={true} />
    ) : (
        <Layout>
            <Outlet />
        </Layout>
    );
}

import { ProductWithCategory } from "@app/api"
import { TRANSACTION } from "@app/common";

export const formateProducts = (products: ProductWithCategory[]): { buyFrom: ProductWithCategory[], sellTo: ProductWithCategory[] } => {
    const sellTo: ProductWithCategory[] = [];
    const buyFrom: ProductWithCategory[] = [];

    for (const product of products) {
        if (product.transaction === TRANSACTION.WANTED) sellTo.push(product);
        else buyFrom.push(product);
    }

    return { buyFrom: buyFrom.sort((a, b) => a.price - b.price), sellTo: sellTo.sort((a, b) => b.price - a.price) };
}
import React, { useState, ChangeEvent, FormEvent } from 'react';

const OtpForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        otp: ''
    });

    const { email, otp } = formData;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch('https://lcs-api.devjunction.xyz/api/auth/validate-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message); // Throw an error to be caught in the catch block
            }

            // Authentication successful, handle success response here
            const responseData = await response.json();
            console.log('OTP successful:', responseData);

            // Reset form fields after successful login
            setFormData({
                email: '',
                otp: ''
            });

            // Redirect to dashboard or another page upon successful login
            // Example: window.location.href = '/dashboard';
        } catch {
            console.error('Error in otp verif:');
            // Handle error states or display error message to the user
        }
    };

    return (
        <div className="container login-container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="mt-3 mb-4">
                        <h2 className="text-center">OTP Verification</h2>
                        <p className="text-center">Please enter your email and generated OTP</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input

                                    type="number"
                                    className="form-control"
                                    id="otp"
                                    name="otp"
                                    value={otp}
                                    onChange={handleChange}
                                    placeholder="OTP"
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">
                                Sign Up
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { OtpForm };

import {
    Registration,
    Login,
    AddCard,
    CardListing,
    OTP,
    ForgotPassword,
    Homepage, ChildListing,
    Profile,
    Chat,
    VerifyEmail
} from "@pages/index";

export interface IRoute {
    path: string;
    Component: React.ComponentType;
}

type MRoute = IRoute[]

const publicRoutes: MRoute = [
    { path: '/login', Component: Login },
    { path: '/signup', Component: Registration },
    { path: '/otp', Component: OTP },
    { path: '/forgotPassword', Component: ForgotPassword },
];

const adminRoutes: MRoute = [
];

const userRoutes: MRoute = [
];

const commonRoutes: MRoute = [
    { path: '/addCard', Component: AddCard },
    { path: '/cardListing', Component: CardListing },
    { path: '/childListing', Component: ChildListing },
    { path: '/profile', Component: Profile },
    { path: '/chat', Component: Chat },
]

const routes: MRoute = [
    { path: '/', Component: Homepage },
    { path: '/verifyEmail', Component: VerifyEmail },
    { path: '/verifyEmail/verified', Component: VerifyEmail },
];

export { publicRoutes, adminRoutes, userRoutes, routes, commonRoutes }
import React from "react";

import { ErrorImage } from "@assets/images";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("[ERROR BOUNDARY]", errorInfo);
    console.log("[ERROR BOUNDARY]", error);

    return {
      error,
      errorInfo,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="flex-1 w-100 d-flex flex-column align-items-center justify-content-center gap-4"
          style={{ height: "100vh" }}
        >
          <img
            src={ErrorImage}
            alt="Something went wrong."
            style={{ height: "60vh" }}
          />
          <a className="btn btn-secondary" href="/">
            Go to Home
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };

import { Rating } from '@app/common'
import React from 'react'




export default function RatingStar({ rating }: { rating: number }) {
    const validation = Math.min(Math.max(rating, 0), 5)
    return (
        <div className="star-rating text-center">
            {[...Array(5)].map((_, index) => (
                <Star key={index} filled={index < validation} />
            ))}
        </div>
    )
}

const Star = ({ filled }: { filled: boolean }) => (
    <span className="star" style={{ color: filled ? '#FFD700' : '#DDDDDD' }}>★</span>
);

import { get, put } from "@app/utils/api";
import { IResponse } from "../common-types";
import { UpdateProfileDto } from "@app/common";

export const getuserProfile = async (id: string): Promise<IResponse> => {
    try {
        const response = await get(`user/profile/${id}`)
        return {
            success: true,
            data: response.data
        }

    } catch (e) {
        return {
            success: false,
            data: null
        }
    }
}
export const getAllUsers = async (): Promise<IResponse> => {
    try {
        const resp = await get('users')
        return {
            success: true,
            data: resp.data
        }
    } catch (e) {
        return {
            success: false,
            data: null
        }
    }
}


import { ProfileImage } from '@app/assets/images';
import React, { useEffect, useRef, useState } from 'react';
import "./customStyles.css";
import { UpdateProfileDto, User } from '@app/common';
import RatingStar from './RatingStar';
import { editProfile, uploadMedia } from '@app/api';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getImageLink } from '@app/utils/image';

export default function ProfileCard({ user }: { user: User }) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  console.log(user, 'Users is')

  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false)
  const [description, setDescription] = useState<string>(user?.description || "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos, illum beatae enim deleniti a, consequuntur commodi dicta optio expedita");
  const [editProfileDto, setEditProfileDto] = useState<UpdateProfileDto>({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    avatar: user?.avatar,

    description: user?.description
  });

  const handleEditDescription = async () => {
    if (editDescription) {
      await editProfile({
        ...editProfileDto,
        description: description
      });

      setEditProfileDto(prev => ({
        ...prev,
        description: description
      }));
    }

    setEditDescription(!editDescription);
  };
  const handlePencilClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    if (user?.description) {
      setDescription(user.description);
    }
  }, [user]);
  const handleModel = () => {
    setModal(!modal)
  }
  const handleNameUpdate = async (firstName: string, lastName: string) => {
    await editProfile({
      ...editProfileDto,
      first_name: firstName,
      last_name: lastName

    });

    setEditProfileDto(prev => ({
      ...prev,
      first_name: firstName,
      last_name: lastName
    }));
    setModal(false)

  }


  // const handleImage = async () => {

  //   const
  //     await editProfile({
  //       ...editProfileDto,

  //     })
  // }
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const { success, data } = await uploadMedia(file)

      if (success && data) {
        console.log(data, 'Data is')
        await editProfile({
          ...editProfileDto,
          avatar: data
        })
        setEditProfileDto(prev => ({
          ...prev,
          avatar: data
        }));
      }
    }
  }
  const image = getImageLink(user?.avatar)
  console.log(image, 'Image link is ')
  return (
    <div className="" style={{ width: '25%' }}>

      <EditModal modal={modal} tog={handleModel} firstName={editProfileDto?.first_name} lastName={editProfileDto?.last_name} handleupdate={handleNameUpdate} />
      <div className='d-flex justify-content-center'>
        <div className="position-relative">
          <img
            className="circular-image rounded-circle img-fluid"
            src={user?.avatar ? getImageLink(user?.avatar) : ProfileImage}
            alt="Card image cap"
            style={{ width: '200px', height: '200px', border: 'none' }}
          />

          <input
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              handleFileUpload(e)
            }}
          />
          {/* Pencil icon */}
          <i className="fas fa-pencil-alt position-absolute bottom-0 start-2 translate-middle" onClick={handlePencilClick}></i>
        </div>
      </div>


      <div className="card-body">
        <div className='d-flex justify-content-center' style={{ gap: 15 }}>
          <h5 className="card-title text-center fw-bold">{editProfileDto?.first_name + editProfileDto?.last_name}</h5>
          <i className='fas fa-pencil mt-1' onClick={handleModel}></i>
        </div>
        <h6 className='text-center fw-bold'>{user?.user_name}</h6>
        <h6 className='text-center'>Professional Developer</h6>
        {user?.rating ? <RatingStar rating={user?.rating} /> : 'NA'}
        <hr />
        <div>
          <div className="d-flex justify-content-between">
            <h6 className='text-muted'>From</h6>
            <h6 className='text-muted'>{user?.country}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6 className='text-muted'>Member Since</h6>
            <h6 className='text-muted'>{user?.memberSince}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6 className='text-muted'>Life Time Deals</h6>
            <h6 className='text-muted'>{user?.life_time_deals || 'NA'}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6 className='text-muted'>Avg Response Time</h6>
            <h6 className='text-muted'>{user?.avgResponseTime || 'NA'}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6 className='text-muted'>Avg Rating</h6>
            <h6 className='text-muted'>{user?.rating}</h6>
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <div className="d-flex justify-content-between">
          <h6>Description</h6>
          <a onClick={handleEditDescription} role="button" style={{ cursor: 'pointer', color: 'blue' }}>
            <p className='sm'>{editDescription ? 'Save Description' : 'Edit Description'}</p>
          </a>
        </div>
        {!editDescription ? (
          <p>{description}</p>
        ) : (
          <textarea value={description} onChange={handleDescriptionChange} cols={30} rows={10} />
        )}
      </div>
    </div>
  );
}

const EditModal = ({ modal, tog, firstName, lastName, handleupdate }: { modal: boolean, tog: any, firstName: string, lastName: string, handleupdate: any }) => {

  const [editedFirstName, setEditedFirstName] = useState(firstName);
  const [editedLastName, setEditedLastName] = useState(lastName);


  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedFirstName(event.target.value);
  };


  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedLastName(event.target.value);
  };

  return (
    <Modal isOpen={modal} toggle={tog} centered>
      <ModalHeader className="border-bottom-0" toggle={tog}></ModalHeader>
      <ModalBody>
        <div className='mt-2'>
          <Input type='text' placeholder='Enter First Name' value={editedFirstName} onChange={handleFirstNameChange} />
        </div>

        <div className='mt-2 bg-w'>
          <Input type='text' placeholder='Enter Last Name' value={editedLastName} onChange={handleLastNameChange} />
        </div>

        <div className='d-flex justify-content-end'>
          <Button onClick={() => {
            handleupdate(editedFirstName, editedLastName);
          }}>Edit</Button>
        </div>

      </ModalBody>
    </Modal>
  );
}

import { get, post } from "@app/utils/api";
import {
    CategoryResponse,
    CreateProductPayload,
    CreateProductResponse,
    ProductDetailReponse,
    ProductFilterQuery,
    ProductParentReponse,
    ProductReponse,
    Sub_CategoryResponse
} from "@app/api/product/type";
import { CATEGORY_TYPE, Sub_Category_Type } from "@common";


export const getCategories = async (type: CATEGORY_TYPE | 'All'): Promise<CategoryResponse> => {
    try {
        const res = await get(`product/category/${type}`);

        return {
            success: true,
            data: res.data
        }
    } catch (e) {
        return {
            success: false,
            data: []
        }
    }
}
export const getSubcategories = async (type: Sub_Category_Type | 'All'): Promise<Sub_CategoryResponse> => {
    try {
        const res = await get(`product/sub-category/${type}`);

        return {
            success: true,
            data: res.data
        }
    } catch (e) {
        return {
            success: false,
            data: []
        }
    }
}

export const getProducts = async (payload: ProductFilterQuery): Promise<ProductReponse> => {
    try {
        let qry = '?';
        Object.keys(payload).forEach(key => {
            qry += `${key}=${(payload as any)[key]}&`;
        })
        const res = await get(`product/get${qry}`);

        return {
            success: true,
            data: res.data
        }
    } catch (e) {
        return {
            success: false,
            data: []
        }
    }
}

export const getProductdetail = async (id: string): Promise<ProductDetailReponse> => {
    try {
        const res = await get(`product/${id}`);

        return {
            success: true,
            data: res.data
        }
    } catch (e) {

        return { success: false, data: null }
    }
}

export const getParentProducts = async (payload: ProductFilterQuery): Promise<ProductParentReponse> => {
    try {
        let qry = '?';
        Object.keys(payload).forEach(key => {
            qry += `${key}=${(payload as any)[key]}&`;
        })
        const res = await get(`product/parent${qry}`);

        return {
            success: true,
            data: res.data
        }
    } catch (e) {
        return {
            success: false,
            data: []
        }
    }
}

export const createProduct = async (payload: CreateProductPayload): Promise<CreateProductResponse> => {
    try {
        const res = await post('product', payload);

        return {
            success: true,
            product: res.data
        }
    } catch (e) {

        return { success: false, product: null }
    }
}


import { BottomNavbar } from '@app/layout'
import React, { useEffect, useState } from 'react'
import ProfileCard from './components/ProfileCard'
import { Col, Row } from 'reactstrap'
import RateCard from './components/RateCard'
import { getuserProfile } from '@app/api/user/user.api'
import { APP_CONSTANTS, Rating, UserProfile } from '@app/common'

export function Profile() {
    const userString = localStorage.getItem(APP_CONSTANTS.USER);
    const user = userString ? JSON.parse(userString) : null;

    const [profile, setProfile] = useState<UserProfile | null>(null)
    const getProfile = async () => {
        if (user) {
            const { success, data } = await getuserProfile(user._id)
            if (success && data) {
                setProfile(data);
            }
        }
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <div>
            <BottomNavbar />
            <div className='d-flex Page-content flex-column flex-md-row'>
                {
                    profile?.user ?
                        <ProfileCard user={profile?.user} /> : null
                }
                <div className='mx-4 ps-2'>
                    <div className="d-flex justify-content-center">
                        <button type="button" className="medium-text btn btn-outline-primary me-2 btn-lg btn-rounded " style={{ color: 'black' }}>View My Buys</button>
                        <button type="button" className="btn btn-outline-primary btn-lg medium-text" style={{ color: 'black' }}>View My Sells</button>
                    </div>
                    <Row>
                        {
                            profile?.rating ?
                                profile?.rating.map((item: Rating) => {
                                    return (
                                        <Col md={5}>
                                            <RateCard Rating={item} />
                                        </Col>
                                    )
                                }) : null
                        }
                    </Row>
                </div>
            </div>
        </div>
    )
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ROLE, User } from "@common";
import { RootState } from ".";

interface AuthSlice {
    isAuthenticated: boolean;
    token: string | null;
    refreshToken: string | null;
    role: ROLE | null;
    user: User | null;
}

export interface LoginPayload {
    access_token: string;
    refresh_token: string;
    user: User;
}

interface SetTokenPayload {
    access_token: string;
}

const initialState: AuthSlice = {
    isAuthenticated: false,
    token: null,
    role: null,
    refreshToken: null,
    user: null
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action: PayloadAction<LoginPayload>) {
            state.isAuthenticated = true;
            state.token = action.payload.access_token;
            state.refreshToken = action.payload.refresh_token;
            state.user = action.payload.user;
            state.role = action.payload.user.role;
        },
        logout(state) {
            state.isAuthenticated = false;
            state.token = null;
            state.refreshToken = null;
            state.user = null;
        },
        setToken(state, action: PayloadAction<SetTokenPayload>) {
            state.token = action.payload.access_token;
        },
        updateUser(state, action: PayloadAction<User>) {
            state.user = action.payload;
        }
    },
});

export const { login, logout, setToken, updateUser } = authSlice.actions;
export const AuthSelector = (state: RootState) => state.auth;
export default authSlice;


import { verifyEmail as verifyEmailApi } from '@app/api';
import { Loader } from '@common';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export function VerifyEmail() {
    const isVerified = window?.location?.pathname?.endsWith('/verifyEmail') ? false : true;
    const [emailVerified, setEmailVerified] = useState(false);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        if (isVerified) {
            const token = params.get('token');
            verifyEmail(token || '');
        }
        // eslint-disable-next-line 
    }, []);

    const verifyEmail = async (token: string) => {
        const { success } = await verifyEmailApi(token);
        if (success)
            setEmailVerified(true)
    }
    return (
        <div className="container login-container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="mt-3 mb-4">
                        {
                            isVerified ? (
                                <>
                                    {emailVerified ?
                                        <>
                                            <h2 className="text-center">Your Email was Successfully verified.</h2>
                                            <Link to={'/'}>
                                                <button className="btn btn-primary mt-4">Go to Home</button>
                                            </Link>
                                        </>
                                        :
                                        <Loader />
                                    }
                                </>
                            ) : (
                                <>
                                    <h2 className="text-center">Please verify you Email</h2>
                                    <p className="text-center">A verification link has been sent to your email. Please follow It to verify your Email</p>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

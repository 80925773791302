import { forgotPassword, loginUser } from '@app/api';
import { useState, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const navigate = useNavigate();

    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false); // State to toggle forgot password form
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

    const { email, password } = formData;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleForgotPasswordEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForgotPasswordEmail(e.target.value);
    };

    const handleForgotPassword = () => {
        setShowForgotPasswordForm(true); // Show forgot password form
    };

    const handleForgotPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { success } = await forgotPassword(forgotPasswordEmail);
        if (!success) return;
        navigate('/ForgotPassword', { state: { email: forgotPasswordEmail } });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { success } = await loginUser(email, password)
        if (success)
            navigate('/');
    };

    const handleCreateAccount = () => {
        navigate('/signup')
    }

    return (
        <div className="container login-container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="mt-3 mb-4">

                        {
                            !showForgotPasswordForm ? (
                                <>
                                    <h2 className="text-center">User Login</h2>
                                    <p className="text-center">Please enter your credentials</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={handleChange}
                                                placeholder="Email"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={handleChange}
                                                placeholder="Password"
                                                required
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block">
                                            Sign In
                                        </button>
                                        <div className='d-flex justify-content-end'>
                                            <p className="mt-3 text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-link"
                                                    onClick={handleCreateAccount}
                                                >
                                                    Create Account?
                                                </button>
                                            </p>

                                            <p className="mt-3 text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-link"
                                                    onClick={handleForgotPassword}
                                                >
                                                    Forgot Password?
                                                </button>
                                            </p>


                                        </div>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <h2 className="text-center">Forgot Passowrd</h2>
                                    <p className="text-center">Please enter your credentials</p>
                                    <form onSubmit={handleForgotPasswordSubmit}>

                                        <div className="mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="forgotPasswordEmail"
                                                name="forgotPasswordEmail"
                                                value={forgotPasswordEmail}
                                                onChange={handleForgotPasswordEmailChange}
                                                placeholder="Enter your email"
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary btn-block">
                                            Submit
                                        </button>
                                        <p className="mt-3 text-center">
                                            <button
                                                type="button"
                                                className="btn btn-link"
                                                onClick={() => setShowForgotPasswordForm(false)}
                                            >Back to Login
                                            </button>
                                        </p>
                                    </form>
                                </>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { LoginForm };

import React from 'react'
import { LoginForm } from '@pages/auth/components';

export function Login() {
    return (
        <>
            <LoginForm />
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@hooks';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '@app/routes/private';
import { IRoute, adminRoutes, commonRoutes, publicRoutes, routes, userRoutes } from '@app/routes/routes';
import { PublicRoute } from '@app/routes/public';
import { ROLE, Loader } from '@common';
import { isTokenExpired, isAdmin } from '@utils/helpers';
import { logoutUser, refreshToken } from '@api';

export function Router() {
    const { role, isAuthenticated, token, refreshToken: Rtoken } = useAppSelector((state) => state.auth);
    const { isloading } = useAppSelector((state) => state.app);
    const [appReady, setAppReady] = useState(false);

    useEffect(() => {
        getAppReady();
        // eslint-disable-next-line
    }, [])

    const getAppReady = async () => {
        if (!isAuthenticated) { setAppReady(true); return; };

        const expired = isTokenExpired(token || '');

        if (!expired) { setAppReady(true); return; };

        const refreshExpired = isTokenExpired(Rtoken || '');

        if (refreshExpired) {
            await logoutUser();
            setAppReady(true);
        }
        else {
            await refreshToken(Rtoken || "");
            setAppReady(true);
        }
    }

    if (!appReady) {
        return <Loader />;
    }

    return (
        <>
            {isloading && (
                <Loader />
            )}
            <Routes>
                <Route element={<PublicRoute redirect={false} />}>
                    {routes.map((route: IRoute) => <Route key={route.path} {...route} />)}
                </Route>
                <Route element={<PublicRoute />}>
                    {publicRoutes.map((route: IRoute) => <Route key={route.path} {...route} />)}
                </Route>
                <Route element={<PrivateRoute allowed_roles={[ROLE.ADMIN, ROLE.USER]} />}>
                    {commonRoutes.map((route: IRoute) => <Route key={route.path} {...route} />)}
                </Route>
                {isAdmin(role) ?
                    (
                        <Route element={<PrivateRoute allowed_roles={[ROLE.ADMIN]} />}>
                            {adminRoutes.map((route: IRoute) => <Route key={route.path} {...route} />)}
                        </Route>
                    ) :
                    role === ROLE.USER ?
                        (
                            <Route element={<PrivateRoute allowed_roles={[ROLE.USER]} />}>
                                {userRoutes.map((route: IRoute) => <Route key={route.path} {...route} />)}
                            </Route>
                        )
                        : null
                }
            </Routes>
        </>
    )
}
import API from '@utils/api';
import { IResponse } from '@app/api/common-types'

export const uploadMedia = async (file: File): Promise<IResponse> => {
    try {
        const formData = new FormData()
        formData.append("files", file)
        const res = await API.post("misc/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        const data = res.data?.data ? res.data.data : res.data
        const path = data[0].filename
        if (!path) throw Error("Something went wrong")
        return {
            success: true,
            data: path,
        }
    } catch (err) {
        return {
            success: false,
            data: err,
        }
    }
}
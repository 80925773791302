import { getCategories as getCategoriesApi } from '@api';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "@hooks";
import { Category, CATEGORY_TYPE } from '@common';
import { logout } from '@app/store/auth-slice';
import { GamingImage, GamingImage1, SportsImage, SuppliesImage, SuppliesImage1 } from '@assets/images';

export const BottomNavbar = () => {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const [categories, setCategories] = useState<Category[]>([])

    const getCategories = async () => {
        const { data, success } = await getCategoriesApi('All');
        if (success)
            setCategories(data)

    }

    useEffect(() => {
        getCategories()
    }, [])

    const sports = categories?.filter(c => c.type === CATEGORY_TYPE.SPORTS) || [];
    const gaming = categories?.filter(c => c.type === CATEGORY_TYPE.GAMING) || [];
    const supplies = categories?.filter(c => c.type === CATEGORY_TYPE.SUPPLIES) || [];

    return (
        <div>
            <div className="row justify-content-between align-items-center">
                <div className="img-card col-md-3">
                </div>
                <div className="nav-join col-md-3 text-md-end pr-md-4">
                    {isAuthenticated ?
                        <button className=" btn btn-primary" onClick={() => dispatch(logout())}>
                            LogOut
                        </button>
                        :
                        <Link to='/login' className=" btn btn-primary">
                            Join Now
                        </Link>
                    }
                </div>
            </div>
            <br />
            <div className="row">
                <nav className="navbar navbar-expand-lg">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <div className="mega-dropdown">
                                <button className="mega-dropbtn">Sports</button>
                                <div className="mega-dropdown-content">
                                    <div className='mega-dropdown-wrapper'>
                                        <div className="mega-dropdown-list">
                                            {sports.map((sport) => <Link key={sport._id} to='/cardListing' state={{ category: sport }} className="nav-link">{sport.name}</Link>)}
                                        </div>
                                        <img src={SportsImage} alt='sports' style={{ objectFit: 'contain' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="mega-dropdown">
                                <button className="mega-dropbtn">Gaming</button>
                                <div className="mega-dropdown-content">
                                    <div className='mega-dropdown-wrapper'>
                                        <div className="mega-dropdown-list">
                                            {gaming.map((game) => <Link key={game._id} to='/cardListing' state={{ category: game }} className="nav-link">{game.name}</Link>)}
                                        </div>
                                        <div className='d-flex gap-4'>
                                            <img src={GamingImage} alt='sports' style={{ objectFit: 'contain' }} />
                                            <img src={GamingImage1} alt='sports' style={{ objectFit: 'contain' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mega-dropdown">
                                <button className="mega-dropbtn">Supplies</button>
                                <div className="mega-dropdown-content">
                                    <div className='mega-dropdown-wrapper'>
                                        <img src={SuppliesImage} alt='sports' style={{ objectFit: 'contain' }} />
                                        <div className="mega-dropdown-list">
                                            {supplies.map((supply) => <Link key={supply._id} to='/cardListing' state={{ category: supply }} className="nav-link">{supply.name}</Link>)}
                                        </div>
                                        <img src={SuppliesImage1} alt='sports' style={{ objectFit: 'contain' }} />
                                    </div>
                                </div>
                            </div>
                            <li className="nav-item dropdown">
                                <Link className="nav-link " to="" role="button" aria-expanded="false">
                                    Other
                                </Link>
                            </li>
                        </ul>
                        <form className="d-flex ms-auto">
                            <div className="input-group">
                                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn" type="submit">
                                        <i className="fas fa-search" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </nav>
            </div>
        </div>


    );
};
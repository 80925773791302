import React from "react";
import { Link } from "react-router-dom";

export function Cta1() {
    return (
        <div className="cta">
            <div className="row">
                <div className="col-md-1">

                </div>

                <div className="col-md-7">
                    <div className="row">
                        <div className="col">
                            <h1 className="text-left">Ideal for Set Builders & Player Collectors</h1>
                            <p className="text-left">Build one order over time from multiple sellers, pay shipping once,
                                and receive your cards in one package</p>
                            <Link to="" className="btn btn-primary">Buy Cards</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">WHY YOU SHOULD BUY ON COMMUNITY LCS
                            </h5>
                            <h6 className="card-subtitle">Flat Rate Shipping</h6>
                            <p className="card-text">Buy now, ship later! Purchase over time from
                                many sellers & ship once!</p>
                            <h6 className="card-subtitle">36 Million Cards Under One Roof</h6>
                            <p className="card-text">We house millions of sports cards from thousands
                                of sellers and provide industry renowned
                                customer service!</p>
                            <h6 className="card-subtitle">Easy & Fun Sports Card Flipping</h6>
                            <p className="card-text">Filter by team, player, set & more. Purchase,
                                instantly relist, & flip for profit.</p>

                        </div>
                    </div>
                </div>
                <div className="col-md-1">

                </div>
            </div>
        </div>
    )
}


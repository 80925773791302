import { LoginResponse, ResetPasswordPayload, SignUpPayload } from '@app/api/auth/type';
import { login, logout, setToken } from '@store/auth-slice'
import { store } from '@store/index';
import { IResponse } from '@app/api/common-types'
import { post, put } from '@utils/api'
import { APP_CONSTANTS, UpdateProfileDto } from '@common';

const handleLogin = (res: LoginResponse) => {
    store.dispatch(login(res.data));
    localStorage.setItem(APP_CONSTANTS.TOKEN, res.data.access_token);
    localStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(res.data.user));
}

export const signUp = async (payload: SignUpPayload): Promise<IResponse> => {
    try {
        const res: LoginResponse = await post('auth/signup', payload)
        handleLogin(res)

        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, data: e }
    }
}

export const loginUser = async (email: string, password: string): Promise<IResponse> => {
    try {
        const res: LoginResponse = await post('auth/login', {
            email,
            password
        })
        handleLogin(res)

        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, data: e }
    }
}

export const logoutUser = async () => {
    store.dispatch(logout());
    localStorage.clear();
    window.location.href = '/login';
}

export const refreshToken = async (refresh_token: string): Promise<IResponse> => {
    try {
        const res = await post(`auth/refresh-token/${refresh_token}`, {});
        store.dispatch(setToken(res.data));

        return { success: true, data: res.data };
    } catch (e) {
        logoutUser();
        return { success: false, data: null };
    }
}

export const forgotPassword = async (email: string): Promise<IResponse> => {
    try {
        const res = await post('auth/forgot-password', {
            email: email,
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, data: e }
    }
}

export const resetPassword = async (payload: ResetPasswordPayload): Promise<IResponse> => {
    try {
        const res = await post('auth/reset-password', payload)
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, data: e }
    }
}

export const verifyEmail = async (token: string): Promise<IResponse> => {
    try {
        const res: LoginResponse = await post('auth/verify-email', { token })
        handleLogin(res)

        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, data: e }
    }
}

export const editProfile = async (editProfilePayLoad: UpdateProfileDto): Promise<IResponse> => {
    try {
        const response = await put('auth/profile', editProfilePayLoad)
        return { success: true, data: response.data }

    } catch (e) {
        return { success: true, data: null }
    }
}
import React from 'react'
import { OtpForm } from '@pages/auth/components';

export function OTP() {
    return (
        <>
            <OtpForm />
        </>
    )
}

import { Spinner } from "reactstrap"

export const Loader = () => {
    return <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
    >
        <Spinner
            color="secondary"
            style={{
                height: "4rem",
                width: "4rem",
            }}
        >
            Loading...
        </Spinner>
    </div>
}
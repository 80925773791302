export const APP_CONSTANTS = {
    APP_NAME: "LCS",
    USER: "USER",
    TOKEN: "Token",
}
export const YEARS = Array.from(
    { length: new Date().getFullYear() - 1953 + 1 },
    (_, index) => ({
        id: index + 1, // ID starting from 1
        year: (1953 + index).toString()
    })
).reverse();
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import UserCard from '@pages/chat/components/UserCard';
import { Message } from '@app/common';

export const Chat = () => {
    const classes = useStyles();


    const [messages, setMessages] = useState<Message[]>([
        { _id: "1", message: "Hey man, What's up ?", timestamp: new Date("2023-04-18T09:30:00"), user: "", assignedTo: "" },
        { _id: "1", message: "Hey man, What's up ?", timestamp: new Date("2023-04-18T09:30:00"), user: "", assignedTo: "" },
        { _id: "1", message: "Hey man, What's up ?", timestamp: new Date("2023-04-18T09:30:00"), user: "", assignedTo: "" },
    ]);
    const [recieveMessages, setRecieveMessages] = useState<Message[]>([
        { _id: "1", message: "Hey man, What's up ?", timestamp: new Date("2023-04-18T09:30:00"), user: "", assignedTo: "" },
        { _id: "1", message: "Hey man, What's up ?", timestamp: new Date("2023-04-18T09:30:00"), user: "", assignedTo: "" },
        { _id: "1", message: "Hey man, What's up ?", timestamp: new Date("2023-04-18T09:30:00"), user: "", assignedTo: "" },
    ])
    const [messageInput, setMessageInput] = useState('');

    const handleSendMessage = () => {
        if (messageInput.trim() !== '') {
            const newMessage: Message = {
                _id: messages.length + 1 .toString(),
                message: messageInput,
                timestamp: new Date("2023-04-18T09:30:00")
            };
            setMessages([...messages, newMessage]);
            setMessageInput('');
        }
    };

    return (
        <div className='mb-2'>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" className="header-message">Chat</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3} className={classes.borderRight500}>
                    <Divider />
                    <Grid item xs={12} style={{ padding: '10px' }}>
                        <h6>All Messages</h6>
                    </Grid>
                    <Divider />
                    <List>
                        {Array.from({ length: 5 }, (_, index) => (
                            <UserCard key={index} />
                        ))}
                    </List>
                </Grid>
                <Grid item xs={9}>
                    <List className={classes.messageArea}>
                        {recieveMessages.map(message => (
                            <ListItem key={message._id} className={classes.receivedMessage}>
                                <ListItemText primary={message.message} secondary={message.timestamp?.toString()} />
                            </ListItem>
                        ))}
                        {messages.map(message => (
                            <ListItem key={message._id} className={`${classes.messageItem} ${classes.receivedMessage}`}>
                                <ListItemText style={{ textAlign: 'right' }} primary={message.message} secondary={message.timestamp?.toString()} />
                            </ListItem>
                        ))}
                    </List>

                    <Divider />
                    <Grid container alignItems="center">
                        <Grid item xs={11}>
                            <TextField
                                id="outlined-basic-email"
                                label="Type Something"
                                fullWidth
                                className={classes.messageInput}
                                value={messageInput}
                                onChange={(e) => setMessageInput(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Fab color="primary" aria-label="add" className={classes.sendButton} onClick={handleSendMessage}>
                                <SendIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    },
    messageItem: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    receivedMessage: {
        justifyContent: 'flex-start'
    },
    messageInput: {
        width: '100%'
    },
    sendButton: {
        marginLeft: '8px'
    }
});
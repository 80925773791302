import { signUp, SignUpPayload } from '@app/api';
import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

const UserDetailsForm = () => {
    const [formData, setFormData] = useState<SignUpPayload>({
        first_name: '',
        last_name: '',
        email: '',
        user_name: '',
        password: '',
        company_name: '',
        country: '',
        city: '',
        state: '',
        zip_code: '',
        phone_number: '',
        address: '',
        address_lane2: '',
        otp: '666666',
    });

    const navigate = useNavigate();

    const { first_name, last_name, email, user_name, password, company_name, country, city, state, zip_code, phone_number, address, address_lane2 } = formData;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { success } = await signUp(formData)
        if (success)
            navigate('/verifyEmail');
    };


    return (
        <div className="container login-container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="mt-3 mb-4">
                        <h2 className="text-center">User Details</h2>
                        <p className="text-center">Please provide the following details</p>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_name"
                                        name="first_name"
                                        value={first_name}
                                        onChange={handleChange}
                                        placeholder="First Name"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last_name"
                                        name="last_name"
                                        value={last_name}
                                        onChange={handleChange}
                                        placeholder="Last Name"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={handleChange}
                                        placeholder="Enter Your Email"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="user_name"
                                        name="user_name"
                                        value={user_name}
                                        onChange={handleChange}
                                        placeholder="User Name"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company_name"
                                        name="company_name"
                                        value={company_name}
                                        onChange={handleChange}
                                        placeholder="Company Name"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="country"
                                        name="country"
                                        value={country}
                                        onChange={handleChange}
                                        placeholder="Country"
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        name="city"
                                        value={city}
                                        onChange={handleChange}
                                        placeholder="City"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        name="state"
                                        value={state}
                                        onChange={handleChange}
                                        placeholder="State"
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="zip_code"
                                        name="zip_code"
                                        value={zip_code}
                                        onChange={handleChange}
                                        placeholder="Zip Code"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="phone_number"
                                        name="phone_number"
                                        value={phone_number}
                                        onChange={handleChange}
                                        placeholder="Phone Number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        name="address"
                                        value={address}
                                        onChange={handleChange}
                                        placeholder="Address"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address_lane2"
                                        name="address_lane2"
                                        value={address_lane2}
                                        onChange={handleChange}
                                        placeholder="Address Line 2"
                                    />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                        </form>
                    </div>
                    <p className="text-center">
                        Partypullz.com is doing business for Check Out My LLC and is utilizing patented technology.
                    </p>
                </div>
            </div>
        </div>
    );
};

export { UserDetailsForm };

import { CgTrash } from "react-icons/cg";
import { Modal, ModalBody } from "reactstrap";

type ConfirmationModalProp = {
    show: boolean,
    message: string,
    confirmText: string,
    onDeleteClick: () => void,
    onCloseClick: () => void
}

export const ConfirmationModal = ({
    show,
    message,
    confirmText,
    onDeleteClick,
    onCloseClick,
}: ConfirmationModalProp) => {
    return (
        <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
            <div className="modal-content">
                <ModalBody className="px-4 py-5 text-center">
                    <button
                        type="button"
                        onClick={onCloseClick}
                        className="btn-close position-absolute end-0 top-0 m-3"
                    ></button>
                    <div className="avatar-sm mb-4 mx-auto">
                        <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                            <CgTrash />
                        </div>
                    </div>
                    <p className="text-muted font-size-16 mb-4">{message}</p>

                    <div className="hstack gap-2 justify-content-center mb-0">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={onDeleteClick}
                        >
                            {confirmText}
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={onCloseClick}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    );
};

import React from 'react'
import { ForgotPasswordForm } from '@pages/auth/components';

export function ForgotPassword() {
    return (
        <>
            <ForgotPasswordForm />
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { CartImage } from "@assets/images";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProductFilterQuery, ProductWithCategory, getProducts, getSubcategories } from '@app/api';
import { BOX_TYPE, SubCategory } from '@common';
import { formateProducts } from '@app/utils/formate-products';

export type childListingState = {
    category: string;
    subCategory: string;
    BoxType: BOX_TYPE;
    year: string;
    high_bid: number;
    low_ask: number;
}

const ChildListingLayout = () => {

    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    if (Object.keys(location.state).length < 6) navigate(-1);

    const { category, subCategory, BoxType, year, high_bid, low_ask } = location.state as childListingState;

    const query = useRef<ProductFilterQuery>({ box_type: BoxType, category, sub_category: subCategory, year });

    const [categories, setCategories] = useState<SubCategory[]>([])
    const [buyFrom, setBuyFrom] = useState<ProductWithCategory[]>([])
    const [sellTo, setSellTo] = useState<ProductWithCategory[]>([])
    const [selectedBoxType, setSelectedBoxType] = useState<string>(BoxType)
    const [selectedCategory, setSelectedCategory] = useState(subCategory);

    useEffect(() => {
        if (chartRef.current) {
            if (chartInstance.current) {
                chartInstance.current.destroy(); // Destroy previous chart instance
            }

            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                chartInstance.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['4-Oct-19', '25-Oct-19', '26-Oct-19', '27-Oct-19', '28-Oct-19'],
                        datasets: [
                            {
                                label: '',
                                data: [50, 40, 70, 60, 30],
                                backgroundColor: '#addfff',
                            },
                        ],
                    },
                    options: {
                        scales: {
                            y: {
                                title: {
                                    display: true,
                                    text: '',
                                },
                            },
                            x: {
                                title: {
                                    display: true,
                                    text: 'Date',
                                },
                            },
                        },
                    },
                });
            }
        }

        // Cleanup function to destroy chart instance on component unmount
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, []);

    const getProduct = async () => {
        setBuyFrom([]);
        setSellTo([]);

        const { success, data } = await getProducts(query.current);

        if (!success || data.length === 0) return;

        const { buyFrom, sellTo } = formateProducts(data);
        setBuyFrom(buyFrom);
        setSellTo(sellTo);
    };

    useEffect(() => {
        getProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBoxType, selectedCategory])

    const getCategory = async () => {
        const { success, data } = await getSubcategories('All');
        if (success && data)
            setCategories(data);
    }

    useEffect(() => {
        getCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBoxChange = async (e: any) => {
        setSelectedBoxType(e.target.value);
        query.current = Object.assign(query.current, { box_type: e.target.value });
    }

    const handleSubCategoryChange = async (e: any) => {
        const selectedSubCategoryId = e.target.value;
        if (selectedSubCategoryId === '') {
            let qry = query.current
            delete qry.sub_category
            query.current = qry
            setSelectedCategory('');
        } else {
            query.current = Object.assign(query.current, { sub_category: e.target.value });
            setSelectedCategory(e.target.value);
        }
    }

    return (
        <div className="container-fluid">
            <div className="row child-listing-div">
                <div className="child-listing-col-1 col-md-6 text-md-end text-md-start pr-md-4">
                    <h3>Label</h3>
                    <div className="row">
                        <h5 className="col-sm-6 high-bid">High Bid: ${high_bid}</h5>
                        <h5 className="col-sm-6 low-bid">Low Ask: ${low_ask}</h5>
                    </div>
                    <div className="filters">
                        <div className="row">
                            <div className="col-sm-6">
                                <select aria-label="Form" className="form-select" value={selectedCategory} onChange={handleSubCategoryChange}>
                                    <option disabled > Select Category</option>
                                    {categories.map((item) => (
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <select aria-label="Form" className="form-select" value={selectedBoxType} onChange={handleBoxChange}>
                                    <option value={""} disabled>Select Box-Type</option>
                                    {Object.values(BOX_TYPE).map((boxType, index) => (
                                        <option key={index} value={boxType}>{boxType}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 text-md-end pr-md-4">
                                <Link to="" className="btn btn-primary">Price History</Link>
                            </div>
                            <div className="col-md-6 text-md-end pr-md-4">
                                <Link to="" className="btn btn-primary">Offer History</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 text-md-end pr-md-4">
                                <Link to='/addCard' className="btn btn-primary" state={{ category: category, BoxType: selectedBoxType, subCategory: subCategory }}>
                                    Add New Listing
                                </Link>
                            </div>
                            <div className="col-md-6 text-md-end pr-md-4">
                                <Link to="" className="btn btn-primary">Add Price Alert</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-md-end pr-md-4">
                        <table className="table text-center custom-table">
                            <thead>
                                <tr>
                                    <th className="table-header-child" colSpan={5}>Sell To</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Dealer</th>
                                    <th>Max Qty</th>
                                    <th>Notes</th>
                                    <th>Bid price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sellTo && sellTo?.map((item, index) => (
                                    <tr key={index}>
                                        <td><img src={CartImage} alt="" style={{ height: 20 }} /></td>
                                        <td>{item?.user_info?.name}</td>
                                        <td>{item?.quantity}</td>
                                        <td>{item?.description}</td>
                                        <td>{item?.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12 text-md-end pr-md-4">
                        <table className="table text-center buy-from custom-table">
                            <thead>
                                <tr>
                                    <th className="table-header-child" colSpan={5}>Buy From</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Dealer</th>
                                    <th>Max Qty</th>
                                    <th>Notes</th>
                                    <th>Ask price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {buyFrom && buyFrom?.map((item, index) => (
                                    <tr key={index}>
                                        <td><img src={CartImage} alt="" style={{ height: 20 }} /></td>
                                        <td>{item?.user_info?.name}</td>
                                        <td>{item?.quantity} </td>
                                        <td>{item?.description}</td>
                                        <td>{item?.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="chart-div col-md-4 text-md-end pr-md-4">
                    <canvas ref={chartRef} />
                    <div>
                        <h5>Product Info</h5>
                        <p>#Boxes Trade :$163</p>
                        <p>#Boxes Trade :$163</p>
                        <p>#Boxes Trade :$163</p>
                        <p>#Boxes Trade :$163</p>
                        <h5>Recent Trades</h5>
                        <p>#Boxes Trade :$163</p>
                        <p>#Boxes Trade :$163</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ChildListingLayout };
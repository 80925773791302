import React from 'react';
import {
    FacebookImage,
    TwitterImage,
    InstagramImage,
    LinkedinImage,
    MailImage,
    ProfileImage
} from '@assets/images';

import { Link } from 'react-router-dom';

export const Navbar = () => {

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="d-flex">
                <Link className="navbar-brand" to="">
                    <img src={FacebookImage}
                        alt="Icon 1" />
                </Link>
                <Link className="navbar-brand" to="">
                    <img src={InstagramImage}
                        alt="Icon 2" />
                </Link>
                <Link className="navbar-brand" to="">
                    <img src={LinkedinImage}
                        alt="Icon 3" />
                </Link>
                <Link className="navbar-brand" to="">
                    <img src={TwitterImage}
                        alt="Icon 4" />
                </Link>
            </div>

            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="">FAQs</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="">Membership</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="">About Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="">Contact Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="navbar-brand" to="">
                            <i className="far fa-bell" style={{ color: '#ffffff' }}></i> </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="navbar-brand" to="">
                            <img src={MailImage}
                                alt="Bootstrap" />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/profile">
                            <img src={ProfileImage}
                                alt="Bootstrap" className="rounded-circle" style={{ width: "40px", height: "40px" }} />
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

import { ProfileImage } from '@app/assets/images'
import { Rating } from '@app/common'
import { getImageLink } from '@app/utils/image'
import React from 'react'
import { Col, Row } from 'reactstrap'
import RatingStar from './RatingStar'

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ Rating }: { Rating: Rating }) {
    return (
        <div className='d-flex'>

            <div>
                <img src={Rating?.user_info?.avatar ? getImageLink(Rating?.user_info?.avatar) : ProfileImage} className='rounded-circle' style={{ border: 'none' }} />

            </div>

            <div>
                <h4 className='ms-2 mt-2 mb-0' style={{ fontWeight: 400 }}>{Rating.user_info.name}</h4>
                <Col className="mt-0 ms-2">
                    {Rating?.rating ? <RatingStar rating={Rating?.rating} /> : 'NA'}
                </Col>
                <p>{Rating?.feedback}</p>
                <div className='d-flex justify-content-end' style={{ gap: 10 }}>

                    <a>Reply</a>
                    <a> Report</a>
                </div>
            </div>
        </div>


    )
}

import React from "react";

export function Newsletter() {
    return (
        <section className="section-last">
            <h1> Invite your friend to join our platform when they
                sign up you will get $10!</h1>
            <div className="email">
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter your Email
                "/><button className=" btn btn-primary">Subscribe</button>
                </div>

            </div>

        </section>
    )
}

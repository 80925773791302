import { resetPassword } from '@app/api';
import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
    const location = useLocation();
    const { email } = location.state as { email: string };
    const [formData, setFormData] = useState({
        email: email,
        password: '',
        cpassword: '',
        otp: ''
    });
    const navigate = useNavigate();
    const { password, cpassword, otp } = formData;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { success } = await resetPassword(formData)
        if (success)
            navigate('/login');
    };

    return (
        <div className="container login-container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="mt-3 mb-4">
                        <h2 className="text-center">Reset Password</h2>
                        <p className="text-center">Please enter the details</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    placeholder="New Password"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="cpassword"
                                    name="cpassword"
                                    value={cpassword}
                                    onChange={handleChange}
                                    placeholder="Confirm Password"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input

                                    type="number"
                                    className="form-control"
                                    id="otp"
                                    name="otp"
                                    value={otp}
                                    onChange={handleChange}
                                    placeholder="OTP"
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">
                                Reset
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ForgotPasswordForm };

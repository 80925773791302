import React from 'react'
import { CardTable } from '@pages/card/components';
import { BottomNavbar } from '@app/layout';

export function CardListing() {
    return (
        <>
            <BottomNavbar />
            <CardTable />
        </>
    )
}

import React from "react";
import { BannerImage, Banner2Image, Banner3Image } from "@assets/images";
import { Carousel, CarouselItem } from 'reactstrap';

export function Banner() {
    const [activeIndex, setActiveIndex] = React.useState(0);


    const next = () => {
        if (activeIndex === 2) {
            return setActiveIndex(0);
        }
        setActiveIndex(activeIndex + 1);
    }

    const previous = () => {
        if (activeIndex === 0) {
            return setActiveIndex(2);
        }
        setActiveIndex(activeIndex - 1);
    }

    return (
        <Carousel onClick={next} enableTouch activeIndex={activeIndex} className="banner" next={next} previous={previous}>
            <CarouselItem key={'banner1'}><Image image={BannerImage} /></CarouselItem>
            <CarouselItem key={'banner2'}><Image image={Banner2Image} /></CarouselItem>
            <CarouselItem key={'banner3'}><Image image={Banner3Image} /></CarouselItem>
        </Carousel>
    );
}

const Image = ({ image }: { image: string }) => {
    return (
        <img className="d-block w-100" style={{ 'objectFit': 'cover', maxHeight: '450px' }} src={image} alt="slide" />
    )
}
